import React from "react";
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n";

import Layout from "../components/Layout";
import HelpMenu from "../components/HelpMenu";
import QuestionEN3 from "../components/Questions/QuestionEN3";
import QuestionDE3 from "../components/Questions/QuestionDE3";
import QuestionFR3 from "../components/Questions/QuestionFR3";

export default function HelpPage() {
    const { locale } = useLocalization();

    return (
        <Layout>
            <main className="static help-wrap">               
                <div className="hamburger-help-wrap mobile-only">                      
                    <Link to="/help" className="hamburger-help"></Link>
                </div>
                <div className="help-menu mobile-hidden"><HelpMenu /></div>
                <div className="help-content">
                    { (locale == "en") && <QuestionEN3 /> }
                    { (locale == "de") && <QuestionDE3 /> }
                    { (locale == "fr") && <QuestionFR3 /> }
                </div>
            </main>
        </Layout>
    );
}
